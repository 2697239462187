import '../../css/Vision.scss';
import React, { useEffect, useRef, } from 'react';
const Vision = () => {

    return (
        <>
            <div className="vision_wrap">
                <div className="vision_1_cont_box">
                    <img src="/img/p_vision.png" alt="" />
                    <div className="vision_1_txt_cont_box">
                        <div className="vision_1_txt_box">
                            <span className='vision_1_1_txt f_bigger'>비전</span>
                            <span className='vision_1_2_txt f_big'>누구도 소외받지 않는 사랑과 나눔의 꿈꾸는 사회실천</span>
                            <span className='vision_1_3_txt f_noraml'>김포청년희망연대는 사회적 약자와 더불어 국가를 위해 헌신했던 '빈곤어르신'들과 희망의 씨앗인 '어린이'들에게 꿈꾸는 '내일'을 약속합니다.</span>
                        </div>
                        <div className="vision_1_txt_box">
                            <span className='vision_1_1_txt f_bigger'>미션</span>
                            <span className='vision_1_2_txt f_big'>정부의 지원금에 의존하지 않는 스스로 살아 숨 쉬는 NGO 단체</span>
                            <span className='vision_1_3_txt f_noraml'>김포청년희망연대는 정치, 종교와 같은 특정 이해집단에 얽매이지 않으며 정부와 기업의 지원금에 의존하지 않는 시민들의 십시일반으로 기능하는 시민의, 시민에 의한, 시민을 위해 운영되는 독립적인NGO 단체를 지향합니다.</span>
                        </div>
                    </div>
                </div>
                <div className="vision_2_cont_box">
                    <div className="vision_2_tit_txt_box">
                        <span className='f_bigger'><span className='vision_2_tit f_bigger'>김포청년희망연대</span>의 6대 정신</span>
                    </div>
                    <div className="vision_2_1_cont_box">
                        <div className="vision_2_1_txt_box">
                            <span className='f_normal '>순수성 : <span className='f_normal vision_2_2_txt'>어떠한 것도 바라지 않고 행동하는 진실한 나눔과 사랑의 정신</span></span>
                            <span className='f_normal'>지속성 : <span className='f_normal vision_2_2_txt'>일시적이고 단발적인 행동이 아닌 소외계층에 대해 꾸준하고 지속적인 사랑과 관심</span></span>
                            <span className='f_normal'>자발성 : <span className='f_normal vision_2_2_txt'>누군가의 부탁에 의하지 않고 스스로 생각하고 실천하는 자세</span></span>
                            <span className='f_normal'>이타성 : <span className='f_normal vision_2_2_txt'>타인의 안녕을 증진하기 위해 보상과 상관없이 남을 돕고자 하는 마음</span></span>
                            <span className='f_normal'>공익성 : <span className='f_normal vision_2_2_txt'>영리를 목적으로 하지 않고 공공의 이익을 도모하는 마음</span></span>
                            <span className='f_normal'>투명성 : <span className='f_normal vision_2_2_txt'>엄격한 법질서와 도덕적 기준으로 기부금을 투명하고 공정하게 집행하는 윤리</span></span>
                        </div>
                        <div className="vision_2_1_img_box">
                            <img src="/img/p_vision_bottom.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Vision;