import '../../css/Mentoring.scss';
import React, { useEffect, useState, useRef } from 'react';

const Mentoring = () => {

    const mentors = [
        {
            name: "교육멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
        {
            name: "봉사멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
        {
            name: "지역멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
        {
            name: "사회멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
        {
            name: "지역멘토 멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
        {
            name: "지역멘토 조춘상 멘토",
            img: "/img/main_1.jpeg", // 이미지 경로
        },
    ];


    return (
        <>
            <div className="mentoring_container">
                <div className="mentoring_wrap">
                    {mentors.map((mentor, index) => (
                        <div className="mentoring_box" key={index}>
                            {/* 멘토 이미지 */}
                            <img src={mentor.img} alt={mentor.name} className="mentoring_img" />
                            
                            {/* 멘토 이름 */}
                            <div className="mentoring_name f_normal">{mentor.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Mentoring;