import '../../css/Social.scss';
import React, { useEffect, useState, useRef } from 'react';

const Social = () => {

    const socialData = [
        {
            title: "복지문화",
            items: [
                "주거환경 개선",
                "연탄 봉사",
                "헌혈, 배식, 김장, 농촌, 집수리 등",
                "유기견 미용",
                "행사 협력",
                "다문화 관련 등",
            ],
        },
        {
            title: "기후전략",
            items: [
                "플로깅",
                "벽화 봉사",
                "식목일",
                "환경의 날",
                "기후 캠페인 활동",
                "자원 순환, 생태, 하천",
            ],
        },
        {
            title: "기부문화",
            items: [
                "탈북자 지원사업",
                "노인/고아원/소녀가정 등 지원사업",
                "기부 홍보 및 활동 지원사업",
                "후원금 관련 기획사업",
                "결식아동 및 1인가구 지원사업",
            ],
        },
        // {
        //     title: "장학지원",
        //     items: [
        //         "후원 기업 업무 협약",
        //         "청년 혜택 및 청년 창업",
        //         "대상자 서류 심사 및 위원회",
        //         "보이스피싱/성폭력/사이버폭력 교육",
        //         "청소년 및 청년, 특기성 장학금",
        //         "대학생, 다문화, 탈북생 등 지식 트레이닝",
        //     ],
        // },
        {
            title: "국제교류",
            items: [
                "외국청년 자원봉사 확대",
                "문화교류 지원",
                "다문화 청년 교류사업",
                "외국가정 지원",
                "청소년 봉사 참여 확대",
            ],
        },
        {
            title: "지역문화",
            items: [
                "공연 축제",
                "전통 문화",
                "역사 탐방 및 관광",
                "지역 특산물 및 플리마켓",
                "문화유산, 음식",
                "문화 강좌 및 체험 활동 기획",
            ],
        },
        {
            title: "문화예술",
            items: [
                "찾아가는 음악회",
                "공연기획 및 제작",
                "청년주도 공연 확대",
                "버스킹 공연",
                "마술쇼 및 마임공연 기획",
            ],
        },
    ];



    return (
        <>
            <div className="social_container">
                <div className="social_contribution_wrap">
                    <div className="social_contribution_circle">
                        <div className="social_contribution_title f_biggest">Social
                        Contribution</div>
                        <div className="social_contribution_desc f_big">
                            청년들의 김포를 위한<br />
                            김포청년희망연대<br />
                            사회공헌본부를 소개합니다!
                        </div>
                    </div>
                    <div className="social_contribution_line"></div>
                    <div className="social_contribution_line_2"></div>
                </div>
                <div className="social_wrap">
                    {socialData.map((category, index) => (
                        <div className="social_box" key={index}>
                            {/* 제목 */}
                            <div className="social_title f_bigger">{category.title}</div>
                            
                            {/* 리스트 */}
                            <div className="social_list_box">
                                {category.items.map((item, itemIndex) => (
                                    <div className="social_list f_normal" key={itemIndex}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Social;