import '../css/Main.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Main = () => {
    const [newsData, setNewsData] = useState([]);
    const [img44Data, setimg44Data] = useState([]);
    const [firstItem, setFirstItem] = useState(null);

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()); // 유효한 날짜인지 확인
    };

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                // 로딩 시작
                const response = await axios.post(`${API_BASE_URL}/gimpo_blog_rss/get_blog`, {}, { withCredentials: true });

                if (response.data && response.data.items) {
                    setNewsData(response.data.items);
                    setFirstItem(response.data.first_item);
                    setimg44Data(response.data.imgs44);
                } else {
                    console.error('유효하지 않은 데이터 형식입니다.');
                }
            } catch (error) {
                console.error('데이터 요청 중 오류 발생:', error);
            } finally {
                // 로딩 종료
            }
        };

        fetchNewsData();
    }, []);

    return (
        <>
            <div className="main_main_warp">
                <div className="main_main_1_box">
                    {firstItem && (
                        <a
                            className="main_main_1_le_box"
                            href={firstItem.href}
                            style={{
                                backgroundImage: `url("${firstItem.thumb_uri || '../../public/img/main_1.jpeg'}")`,
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="main_main_1_le_txt_box">
                                <span className="main_1_1_txt">{firstItem.category}</span>
                                <div></div>
                                <span className="main_1_2_txt f_biggest">{firstItem.title}</span>
                                <span className="main_1_3_txt f_normal">{firstItem.description}</span>
                                <span className="main_1_4_txt f_normal">
                                    {firstItem.date}
                                </span>
                            </div>
                        </a>
                    )}
                    <div className="main_main_1_ri_box">
                        <a className="main_main_1_ri main_main_1_1_box" href="/gimpo" rel="noopener noreferrer">
                            <span className="f_bigger ccsm_q_tit">김포청년희망연대는</span>
                            <span className="f_normal ccsm_q_dis">희망연대가 하는일</span>
                            <div className="ccsm_q_icon_box">
                                <span className="ccsm_q_icon"></span>
                            </div>
                        </a>
                        <a className="main_main_1_ri main_main_1_2_box" href="https://blog.naver.com/gimpo0625/" target="_blank" rel="noopener noreferrer">
                            <span className="f_bigger ccsm_q_tit">희망소식</span>
                            <span className="f_normal ccsm_q_dis">희망연대의 새로운 소식을 알려드립니다.</span>
                            <div className="ccsm_q_icon_box">
                                <span className="ccsm_q_icon"></span>
                            </div>
                        </a>
                        <a className="main_main_1_ri main_main_1_3_box" href="/qna" rel="noopener noreferrer">
                            <span className="f_bigger ccsm_q_tit">단체업무협약</span>
                            <span className="f_normal ccsm_q_dis">기관 단체 업무협약 안내</span>
                            <div className="ccsm_q_icon_box">
                                <span className="ccsm_q_icon"></span>
                            </div>
                        </a>
                        <a className="main_main_1_ri main_main_1_4_box" href="/qna" rel="noopener noreferrer">
                            <span className="f_bigger ccsm_q_tit">개인단원신청</span>
                            <span className="f_normal ccsm_q_dis">개인 봉사 단원 신청 안내</span>
                            <div className="ccsm_q_icon_box">
                                <span className="ccsm_q_icon"></span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="main_main_2_box">
                    <div className="main_main_2_le_box">
                        <span className="main_main_2_tit_txt f_biggest">희망연대 소식</span>
                        {newsData.map((news, index) => (
                            <a
                                key={index}
                                className="main_main_2_cont_box"
                                href={news.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="main_main_2_cont_1_txt f_normal">[{news.category}] {news.title}</span>
                                <span className="main_main_2_cont_2_txt f_normal">
                                    {news.date}
                                </span>
                            </a>
                        ))}
                    </div>
                    <div className="main_main_2_ri_box">
                        <span className="main_main_2_tit_txt f_biggest">홍보영상</span>
                        <a className="main_main_2_ri_link" href="https://www.youtube.com/@%EA%B9%80%ED%8F%AC%EC%B2%AD%EB%85%84%ED%9D%AC%EB%A7%9D%EC%97%B0%EB%8C%80-h3s" target="_blank"></a>
                    </div>
                </div>
                <div className='main_main_3_wrap'>

                    {/* Type 2 출력 */}
                    <div className='mm3_type_wrap'>
                        <span className='main_main_2_tit_txt mm3_type_tit f_biggest'>사회공헌본부</span>
                        {img44Data
                            .filter(img44 => img44.type === '2') // Type 2 필터링
                            .map((img44, index) => (
                                <div className='mm3_item' key={index}>
                                    <a
                                        className='mm3_link'
                                        href={`https://blog.naver.com/gimpo0625/${img44.nbId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div
                                            className="mm3_img_box"
                                            style={{
                                                backgroundImage: `url(${img44.img})`
                                            }}
                                        >
                                        </div>
                                        <span className='mm3_tit f_normal'>[{img44.category}] {img44.title}</span>
                                        <span className='mm3_reg'>{img44.pubDate}</span>
                                    </a>
                                </div>
                            ))}
                    </div>

                    {/* Type 1 출력 */}
                    <div className='mm3_type_wrap'>
                        <span className='main_main_2_tit_txt mm3_type_tit f_biggest'>경영지원본부</span>
                        {img44Data
                            .filter(img44 => img44.type === '1') // Type 1 필터링
                            .map((img44, index) => (
                                <div className='mm3_item' key={index}>
                                    <a
                                        className='mm3_link'
                                        href={`https://blog.naver.com/gimpo0625/${img44.nbId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div
                                            className="mm3_img_box"
                                            style={{
                                                backgroundImage: `url(${img44.img})`
                                            }}
                                        >
                                        </div>
                                        <span className='mm3_tit f_normal'>[{img44.category}] {img44.title}</span>
                                        <span className='mm3_reg'>{img44.pubDate}</span>
                                    </a>
                                </div>
                            ))}
                    </div>

                </div>


            </div>
        </>
    );
};

export default Main;