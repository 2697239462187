import '../../css/History.scss';
import React, { useEffect, useRef, } from 'react';
const History = () => {
    const historyData = [

        {
            year: "2023~",
            imgSrc: "/img/main_1.jpeg",
            activities: [
                { date: "2023", description: "아이사랑센터 저소득가정의 학용품 전달" },
                { date: "2023", description: "김포청년회망연대 헌혈캠페인" },
                { date: "2023", description: "제11회 김포한강마라톤대회 봉사활동" },
                { date: "2023", description: "제43회 장애인의 날 기념식 봉사활동" },
                { date: "2023", description: "김포시장애인가족운동회 봉사활동" },
                { date: "2023", description: "졸음운전! 음주운전! 신호위반! 속도위반 홍보봉사활동" },
                { date: "2023", description: "제1회 지구촌 情다운페스티벌 봉사활동" },
                { date: "2023", description: "SBS 세상에이런일이 방송과 함께하는 주거환경개선사업" },
                { date: "2023", description: "통진읍행정복지센터에서 함께 주거환경개선사업" },
                { date: "2023", description: "하성면 및 월곶면 저소득층 연탄나눔 봉사활동" },
                { date: "2023", description: "2023년 산타원정대 봉사활동" }
            ]
        },
        {
            year: "2022~",
            imgSrc: "/img/main_2.jpg",
            activities: [
                { date: "2022", description: "김포경찰서 [전화금융사기 홍보]" },
                { date: "2022", description: "김포청소년국제영화제 조직위원회 홍보" },
                { date: "2022", description: "제10회 김포한강마라톤대회" },
                { date: "2022", description: "김포경찰서 원터치 신고시스템 '그루SOS' 앱 설치 및 비상버튼 설치" },
                { date: "2022", description: "김장나눔봉사" },
                { date: "2022", description: "산타원정대 봉사활동" }
            ]
        }
    ];
    return (
        <>
            <div className="history_wrap">
                {historyData.map((item, index) => (
                    <div className="history_box" key={index}>
                        <div className="history_le_box">
                            <div className="history_le_txt_box">

                                <span className="f_biggest">{item.year}</span>
                            </div>
                            <div className="history_le_img_box">
                                <img src={item.imgSrc} alt={`main_${index + 1}`} />
                            </div>
                        </div>
                        <div className="history_cn_box"></div>
                        <div className="history_ri_box">
                            {item.activities.map((activity, idx) => (
                                <div className="history_ri_cont_box" key={idx}>
                                    <span className="history_ri_le_txt">{activity.date}</span>
                                    <span className="history_ri_ri_txt">{activity.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default History;