import '../../css/Organization.scss';
import React from 'react';

const Organization = () => {
    return (
        <div className="oz_page_wrap">

            <div className="oz_wrap">
                <div className="oz_row oz_row_1">
                    <div className="oz_col_l"></div>
                    <div className="oz_col_c">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#03598c' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>회장</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r"></div>
                </div>

                <div className="oz_row oz_row_2">
                    <div className="oz_col_l"></div>
                    <div className="oz_col_c"></div>
                    <div className="oz_col_r">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#158dbf' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>감사실</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="oz_row oz_row_3">
                    <div className="oz_col_l">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#1552a3' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>자문위원회</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_c">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#1552a3' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>상임부회장</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#1552a3' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>멘토위원회</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="oz_row oz_row_4">
                    <div className="oz_col_l"></div>
                    <div className="oz_col_c">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#15aebe' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>사무총장</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r"></div>
                </div>

                <div className="oz_row oz_row_5">
                    <div className="oz_col_l"></div>
                    <div className="oz_col_c">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only" style={{ /* backgroundColor: '#15aebe' */ }}>
                                <span className="f_normal" style={{ color: '#fff' }}>총괄본부장</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r"></div>
                </div>

                <div className="oz_row oz_row_6">
                    <div className="oz_col_l oz_col_50">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">경영지원본부</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r oz_col_50">
                        <div className="oz_item">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">사회공헌부</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="oz_row oz_row_7">
                    <div className="oz_col_l oz_col_50">
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">봉사협력</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">행정재무</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">운영지원</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">체육진흥</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">홍보교류</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">장학지원</span>
                            </div>
                        </div>
                    </div>
                    <div className="oz_col_r oz_col_50">
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">기부문화</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">복지문화</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">기후전략</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">지역문화</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">국제교류</span>
                            </div>
                        </div>
                        <div className="oz_item oz_item_33">
                            <div className="oz_i_tit oz_i_tit_only">
                                <span className="f_normal">문화예술</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Organization;