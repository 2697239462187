import '../../css/Notice.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertTriangle } from "react-icons/fi";
import axios from 'axios';
import common from '../../common';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Notice = () => {

    // const { pToast, useAlert, useModal, useConfirm, useLoading } = common();
    // const { pAlert, AlertComponent } = useAlert();
    // const { pModal, ModalComponent} = useModal();
    // const { pConfirm, ConfirmComponent} = useConfirm();
    // const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    // const navigate = useNavigate(); // useNavigate 훅 사용

    // const [status, setStatus] = useState(null); // API 결과값 저장 ('2', '3' 등)

    // const params = new URLSearchParams(window.location.search);
    // const fetchedCode = params.get('code'); // URL에서 code 추출

    // useEffect(() => {

    //     const fetchCode = async () => {

    //         try {
    //             pLoadingOn();
    //             const response = await axios.post(`${API_BASE_URL}/mail_feedback/chk_code`, {
    //                 code: fetchedCode,
    //             });

    //             if (response.data.result === 't') {
    //                 const data = response.data.data[0].status;
    //                 setStatus(data); // 결과값 '2' 또는 '3' 저장
    //             } else {
    //                 pAlert(response.data.msg);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching code or posting data:', error);
    //         } finally {
    //             pLoadingOff();
    //         }
    //     };


    //     fetchCode();
    // }, []);

    // const handleButtonClick = async () => {


    //     pConfirm(
    //         '이메일 수신 거부',
    //         <>
    //             <div>이메일 수신 거부에 동의하시겠습니까?</div>
    //         </>,
    //         '400px',
    //         'auto',
    //         async () => {
    //             try {

    //                 pLoadingOn();
    //                 const response = await axios.post(`${API_BASE_URL}/mail_feedback/up_code`, {
    //                     code: fetchedCode,
    //                 });

    //                 if (response.data.result === 't') {
    //                     pAlert('이메일 수신거부가 완료되었습니다.');
    //                     setTimeout(() => {
    //                         navigate('/');
    //                     }, 2000);
    //                 } else {
    //                     pAlert(response.data.msg);
    //                 }
    //             } catch (error) {
    //                 pAlert('실패');
    //             } finally {
    //                 pLoadingOff();
    //             }
    //         }
    //     );

    // };

    return (
        <>
            {/* <ModalComponent/>
            <AlertComponent/>
            <ConfirmComponent/>
            <LoadingComponent/> */}
            <div className="">
                {/* <span>공지사항</span> */}
            </div>
        </>
    );
};

export default Notice;
