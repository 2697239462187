import '../../css/Partnership.scss';
import React, { useEffect, useState, useRef } from 'react';

const Partnership = () => {


    const partnerships = [
        {
            name: "김포경찰서",
            img: "/img/org_1.png", // 로고 이미지 경로
        },
        {
            name: "김포대학교",
            img: "/img/org_2.png", // 로고 이미지 경로
        },
        {
            name: "김포 도시",
            img: "/img/org_3.png", // 로고 이미지 경로
        },
        {
            name: "김포시자원봉사센터",
            img: "/img/org_4.png", // 로고 이미지 경로
        },
        {
            name: "김포시장애인체육회",
            img: "/img/org_5.png", // 로고 이미지 경로
        },
        {
            name: "김포시체육회",
            img: "/img/org_6.png", // 로고 이미지 경로
        },
        {
            name: "한국교통장애인협회",
            img: "/img/org_7.png", // 로고 이미지 경로
        },
        {
            name: "김포경찰서",
            img: "/img/org_1.png", // 로고 이미지 경로
        },
        {
            name: "김포대학교",
            img: "/img/org_2.png", // 로고 이미지 경로
        },
        {
            name: "김포 도시",
            img: "/img/org_3.png", // 로고 이미지 경로
        },
        {
            name: "김포시자원봉사센터",
            img: "/img/org_4.png", // 로고 이미지 경로
        },
        {
            name: "김포시장애인체육회",
            img: "/img/org_5.png", // 로고 이미지 경로
        },
        {
            name: "김포시체육회",
            img: "/img/org_6.png", // 로고 이미지 경로
        },
        {
            name: "한국교통장애인협회",
            img: "/img/org_7.png", // 로고 이미지 경로
        },
        {
            name: "김포경찰서",
            img: "/img/org_1.png", // 로고 이미지 경로
        },
        {
            name: "김포대학교",
            img: "/img/org_2.png", // 로고 이미지 경로
        },
        {
            name: "김포 도시",
            img: "/img/org_3.png", // 로고 이미지 경로
        },
        {
            name: "김포시자원봉사센터",
            img: "/img/org_4.png", // 로고 이미지 경로
        },
        {
            name: "김포시장애인체육회",
            img: "/img/org_5.png", // 로고 이미지 경로
        },
        {
            name: "김포시체육회",
            img: "/img/org_6.png", // 로고 이미지 경로
        },
        {
            name: "한국교통장애인협회",
            img: "/img/org_7.png", // 로고 이미지 경로
        },
    ];

    

    return (
        <>
            <div className="partnership_container">

                <div className="partnership_wrap">
                    {partnerships.map((partner, index) => (
                        <div className="partnership_box" key={index}>
                            {/* 로고 이미지 */}
                            <img src={partner.img} alt={partner.name} className="partnership_img" />
                            
                            {/* 로고 이름 */}
                            <div className="partnership_name f_normal">{partner.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Partnership;