import '../../css/Ci.scss';
import React, { useEffect, useRef, } from 'react';
const Ci = () => {

    return (
        <>
            <div className="ci_wrap">
                <img src="/img/ci.jpg" alt='ci' />
            </div>
        </>
    );
};

export default Ci;