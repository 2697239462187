
function Footer() {

    return (
        <>
            <div className="footer_cooperation_wrap">
                <div className="footer_cooperation_box">
                    <img src="/img/org_1.png" alt="org_1" />
                    <img src="/img/org_2.png" alt="org_2" />
                    <img src="/img/org_3.png" alt="org_3" />
                    <img src="/img/org_4.png" alt="org_4" />
                    <img src="/img/org_5.png" alt="org_5" />
                    <img src="/img/org_6.png" alt="org_6" />
                    <img src="/img/org_7.png" alt="org_7" />
                </div>
            </div>
            <div className="footer_container">
                <div className="footer_wrap">
                    <div className="footer_text_box">
                        <div className="footer_group_box">
                            <span>김포청년희망연대</span>
                            <span>주소: 경기도 김포시 태장로 795번길 23 607호</span>
                        </div>
                        <div className="footer_group_box">
                            <span>사업자번호 : 872-80-02481</span>
                        </div>
                        <div className="footer_group_box">
                            <span>Copyright © 김포청년희망연대. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;