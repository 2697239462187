import '../../css/Ambassador.scss';
import React, { useEffect, useState, useRef } from 'react';

const Ambassador = () => {


    const ambassadors = [
        {
            name: "영화배우 조춘상",
            img: "/img/main_1.jpeg", // 이미지 경로
            title: "많은 분들께 희망과 사랑을 드리겠습니다.",
            description: `안녕하세요? 저는 영화배우 조춘상입니다.
    
                배우라는 꿈을 꾸면서 저는 세계적인 배우 오드리 헵번(Audrey Hepburn)처럼 되고 싶다는 생각을 했습니다. 
                오드리 헵번(Audrey Hepburn)은 외모도 아름다우시지만, 소외된 이웃을 돕는 아름다운 마음을 가진 배우입니다. 
                그래서 저도 연기 활동을 하면서 오드리 헵번(Audrey Hepburn)처럼 제가 필요한 곳이 있다면 어디든 달려가 최선을 다해 봉사하고 많은 분들께 희망과 사랑을 전파하고 싶습니다.
            `,
        },
        {
            name: "가수 조춘상",
            img: "/img/main_1.jpeg", // 이미지 경로
            title: "소외된 이웃에 마음의 안식처가 되고 싶습니다.",
            description: `안녕하세요? 저는 가수 조춘상입니다.
    
                가수 활동을 하면서 예술은 사람들과 함께 나누고 함께 사는 기쁨을 알게 해주는 것이라고 계속 생각해 왔습니다. 저의 이러한 생각을 실현할 수 있는 기회를 주셔서 너무나도 감사드립니다.
            `,
        },
        {
            name: "가수 조춘상",
            img: "/img/main_1.jpeg", // 이미지 경로
            title: "소외된 이웃에 마음의 안식처가 되고 싶습니다.",
            description: `안녕하세요? 저는 가수 조춘상입니다.
    
                가수 활동을 하면서 예술은 사람들과 함께 나누고 함께 사는 기쁨을 알게 해주는 것이라고 계속 생각해 왔습니다. 저의 이러한 생각을 실현할 수 있는 기회를 주셔서 너무나도 감사드립니다.
            `,
        },
    ];

    return (
        <>
            <div className="ambassador_container">
                <div className="ambassador_wrap">
                    {ambassadors.map((ambassador, index) => (
                        <div className="ambassador_box" key={index}>
                            {/* 이미지 */}
                            <img src={ambassador.img} alt={ambassador.name} className="ambassador_img" />
                            
                            {/* 텍스트 박스 */}
                            <div className="ambassador_txt_box">
                                {/* 타이틀 */}
                                <div className="ambassador_tit f_biggest">"{ambassador.title}"</div>
                                
                                {/* 설명 */}
                                <div className="ambassador_exp f_big">{ambassador.description}</div>
                                
                                {/* 이름 */}
                                <div className="ambassador_name f_bigger">{ambassador.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Ambassador;