import '../../css/Advisory.scss';
import React, { useEffect, useState, useRef } from 'react';

const Advisory = () => {


    const committees = [
        {
            title: "장학선정위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "기부문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "복지문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "지역문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "지역문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "지역문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "지역문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
        {
            title: "지역문화위원회",
            chairman: "위원장",
            members: ["위원", "위원", "위원", "위원", "위원"],
        },
    ];

    return (
        <>
            <div className="advisory_container">
                <div className="advisory_group_wrap">
                    {committees.map((committee, index) => (
                        <div className="advisory_group_box" key={index}>
                            {/* 위원회 제목 */}
                            <div className="advisory_group_tit f_bigger">{committee.title}</div>
                            
                            {/* 위원장 */}
                            <div className="advisory_chairman f_big">{committee.chairman}</div>
                            
                            {/* 위원 리스트 */}
                            <div className="advisory_member_box f_normal">
                                {committee.members.map((member, memberIndex) => (
                                    <div className="advisory_member_txt" key={memberIndex}>
                                        {member}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Advisory;