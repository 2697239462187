import '../../css/Management.scss';
import React, { useEffect, useState, useRef } from 'react';

const Management = () => {


    const managementData = [
        {
            title: "운영지원",
            items: [
                "업무지원 및 장소섭외 등",
                "이취임식 및 내부행사 기획",
                "의전지원 및 상신공문관리",
                "관공서 서류관리, 회의록관리",
                "단체행사(창립일, 정기총회, 회의 등)",
                "조직도 관련 지원업무",
            ],
        },
        {
            title: "행정재무",
            items: [
                "후원금 관리",
                "예산안 수립",
                "재무회계 관리",
                "감사 보고",
                "행정서류 관리",
                "기타 행정재무 관리",
            ],
        },
        {
            title: "봉사협력",
            items: [
                "자원봉사 명단 관리",
                "자원봉사센터 등록",
                "지역사회를 위한 봉사계획 수립",
                "지역단체 업무협약 수립",
                "외부 재난지역 봉사",
            ],
        },
        {
            title: "홍보교류",
            items: [
                "언론기사/홍보기획",
                "홈페이지/블로그/페북/인스타/유튜브 등",
                "외부기관 홍보협력 사업",
                "청년기자단, 지역 뉴스/밴드 관리",
                "현수막 및 홍보 관련 등",
                "정보전(서포터즈) 운영",
            ],
        },
        {
            title: "체육진흥",
            items: [
                "화합의 운동",
                "- 축구/산악/배드민턴/여행",
                "- 스키/수상스키/래프팅/마라톤",
                "국토대장정/지역단체 체육교류",
                "기타 체육활동(화합의 한마당)",
            ],
        },
        {
            title: "장학지원",
            items: [
                "후원 기업 업무 협약",
                "청년 혜택 및 청년 창업",
                "대상자 서류 심사 및 위원회",
                "보이스피싱/성폭력/사이버폭력 교육",
                "청소년 및 청년, 특기성 장학금",
                "대학생, 다문화, 탈북생 등 지식 트레이닝",
            ],
        },
    ];

    return (
        <>
            <div className="management_container">
                <div className="management_contribution_wrap">
                    <div className="management_contribution_circle">
                        <div className="management_contribution_title f_biggest">Management Support</div>
                        <div className="management_contribution_desc f_big">
                            청년들의 김포를 위한<br />
                            김포청년희망연대<br />
                            경영지원본부를 소개합니다!
                        </div>
                    </div>
                    <div className="management_contribution_line"></div>
                    <div className="management_contribution_line_2"></div>
                </div>
                <div className="management_wrap">
                    {managementData.map((category, index) => (
                        <div className="management_box" key={index}>
                            {/* 제목 */}
                            <div className="management_title f_bigger">{category.title}</div>
                            
                            {/* 리스트 */}
                            <div className="management_list_box">
                                {category.items.map((item, itemIndex) => (
                                    <div className="management_list f_normal" key={itemIndex}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Management;