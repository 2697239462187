import '../../css/Gimpo.scss';
import React, { useEffect, useRef, } from 'react';
const Gimpo = () => {

    return (
        <>
            <div className="gimpo_warp">
                <div className="gimpo_1_cont_box">
                    <span className='f_bigger'>김포청년희망연대는 <span className='f_biggest gimpo_1_cont_2_txt'>2022년 6월에 출범한 비영리 단체</span>로 <br />복지 사각지대에서 고통 받는 <span className='f_biggest gimpo_1_cont_2_txt'>국내 취약계층의 안정과 자립, 회복</span>을 위해서 활동합니다.</span>
                </div>
                <div className="gimpo_2_cont_box">
                    <span className='f_bigger'>나눔의 가치를 실현하며 개인의 삶과 사회의 지속가능한 변화를 추구합니다.</span>
                    <br />
                    <br />
                    <span className='f_bigger'>긍정적인 기부 문화가 뿌리내리고, 전문적이고 투명한 지원 사업이 전개될 수 있도록 이웃과 더불어, 나눔을 함께하며, 세상을 바꾸는 새 희망이 되자는 사명으로 오늘도 앞장서서 걸어가고 있습니다.</span>
                    <br />
                    <br />
                    <span className='f_bigger'>모두가 존중받는 세상, 인간으로서의 기본권을 누리며 살아가는 세상을 꿈꾸며 김포청년희망연대 청년들이 여러분과 같이 합니다.</span>
                </div>
                <div className="gimpo_3_cont_box">
                    <div className="gimpo_tit_box">
                        <span className='f_biggest gimpo_tit'>김포청년희망연대 다짐</span>
                    </div>
                    <div className="gimpo_3_cont">
                        <span className='f_normal'>나는 자원봉사자로서,</span>
                        <br />
                        <br />
                        <br />
                        <span className='f_bigger'><span className='gimpo_3_count f_biggest'>하나, </span>자랑하지 말자.</span>
                        <br />
                        <span className='f_bigger'><span className='gimpo_3_count f_biggest'>둘, </span>꾸미지말자.</span>
                        <br />
                        <span className='f_bigger'><span className='gimpo_3_count f_biggest'>셋, </span>바라지말자.</span>
                        <br />
                        <span className='f_bigger'><span className='gimpo_3_count f_biggest'>넷, </span>차별하지말자.</span>
                        <br />
                        <span className='f_bigger'><span className='gimpo_3_count f_biggest'>다섯, </span>후회하지말자.</span>
                    </div>
                </div>
                <div className="gimpo_4_cont_box">
                    <div className="gimpo_tit_box">
                        <span className='f_biggest gimpo_tit'>김포청년희망연대는</span>
                    </div>
                    <div className="gimpo_4_cont">
                        <span className='f_bigger'>모든 국민을 위한 좋은 집이 되어야 한다.</span>
                        <br />
                        <br />
                        <br />
                        <span className='f_bigger'>특권을 누리는 사람도, 무사당하는 사람도, 편애하는 사람도, 멸시당하는 사람도</span>
                        <br />
                        <span className='f_bigger'>우리들의 집에는 없습니다.</span>
                        <br />
                        <br />
                        <span className='f_bigger'>우리들의 집에는 누구도 다른 이를 차별하거나 함부로 대하지 않습니다.</span>
                        <br />
                        <br />
                        <span className='f_bigger'>우리들의 집에는 남을 이용해 자신의 이익을 챙기거나 바라는 사람도 없습니다.</span>
                        <br />
                        <br />
                        <span className='f_bigger'>우리들의 집에는 강한 자가 약한 자를 눌러 빼앗는 법도 없습니다.</span>
                        <br />
                        <br />
                        <span className='f_bigger'>우리들의 집에는 가지지 못한 자를 위하여 자신의 것을 내놓을 줄 아는 사람만이 있을 뿐입니다.</span>
                        <br />
                        <br /><br />
                        <br />
                        <span className='f_bigger'>김포청년희망연대에는 오로지 박애, 평등, 배려, 나눔, 소망 그리고 사랑만이 있을 뿐입니다.</span>

                    </div>
                </div>
                {/* <div className="gimpo_5_cont_box">
                    <img src="/img/gimpo.jpg" alt="" />
                    <span className='f_bigger'>김포청년희망연대에는 오로지 박애, 평등, 배려, 나눔, 소망 그리고 사랑만이 있을 뿐입니다.</span>

                </div> */}
            </div >
        </>
    );
};

export default Gimpo;